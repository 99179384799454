
            @import "src/app/global/_variables.scss";
.header {
    position: relative;
    background: rgba(12, 13, 13, 0.8);
    backdrop-filter: blur(16px);
    border-bottom: 1px solid #252929;
    height: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 10;
    justify-content: center;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1000ms;

    &.scroll_down {
        top: -100px;
    }

    @media screen and (max-width: 1100px) and (min-width: 768px) {
        min-height: 200px;
        &.scroll_down {
            top: -200px;
        }
    }

    @media screen and (max-width: 768px) {
        height: 78px;
        background-color: $background-color;
        &.scroll_down {
            top: -78px;
        }
    }
}

.headerContainer {
    display: flex;
    margin: 0 72px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1920px;
    gap: 100px;

    @media screen and (max-width: 1100px) and (min-width: 768px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}
.desktopNavigation {
    display: flex;
    flex-direction: row;
    gap: 56px;
}

.headerArrow {
    transition-duration: 500ms;
    &.hover {
        transform: rotate(45deg);
    }
}

.headerButtonText {
    display: flex;
    width: 100%;
    white-space: wrap;
    text-overflow: ellipsis;
}

.mobileHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin: 0px 32px;
}

.mobileMenuButton {
    width: 56px;
    height: 35px;
    word-break: break-all;
    text-align: right;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.78em;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.mobileNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
}

.headerMenu {
    position: fixed;
    left: 0px;
    opacity: 0;
    top: -100vw;
    border-top: 1px solid gray;
    padding-top: 56px;
    padding-bottom: 42px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    // height: calc(100vh - 78px - 56px);
    width: 100vw;
    background-color: $background-color;

    transition: all ease-in-out 1s;
    z-index: -1;
    pointer-events: none;
    &.open {
        opacity: 100;
        top: 78px;
        pointer-events: all;
    }
}

.langSelector {
    display: flex;
    gap: 32px;
}

.langItem {
    color: white;
    font-weight: 400;
    font-size: 20px;
    padding: 10px;

    transition: all ease-in-out 0.3s;
}

.langItem.active {
    border-top: 2px solid $primary-color;
}
