
            @import "src/app/global/_variables.scss";


.Typography {
    color: $text-color;
    margin: 0;
    &.light {
        font-weight: 300;
    }
    &.standard {
        font-weight: 400;
    }
    &.medium {
        font-weight: 500;
    }
    &.bold {
        font-weight: 700;
    }
    &.grayscale {
        opacity: 50%;
    }
    &.dark {
        color: $dark-text-color;
    }
    &.gray {
        color: #898989;
    }
}

.h1 {
    font-family: 'Berthold Standard';
    font-size: 96px;
}

.h2 {
    font-family: 'Berthold Standard';
    font-size: 72px;
}

.h3 {
    font-family: 'Berthold Standard';
    font-size: 42px;
}

.h4 {
    font-family: 'Berthold Standard';
    font-size: 24px;
}

.p {
    font-family: 'Berthold Standard';
    font-size: 20px;
}
