
            @import "src/app/global/_variables.scss";
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px;
    border-top: 1px solid gray;
    background-color: white;
    margin-top: 120px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
    }
}

.footer_dark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px;
    border-top: 1px solid gray;
    background-color: $background-color;
    margin-top: 120px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
    }
}
