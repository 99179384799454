
            @import "src/app/global/_variables.scss";
.directions {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    
}

.directions__item {
    margin-top: 42px;
    display: flex;
    gap: 24px;
    align-items: start;
    transition-duration: 500ms;

    cursor: pointer;
    border-bottom: 2px solid rgba($color: gray, $alpha: 0.5);

    padding-top: 16px;
    padding-bottom: 16px;

    &.directions__hover_true {
        background-color: $primary-color;
    }
}

.directions__item-title {
    @media screen and (max-width: 768px) {
        font-size: 32px;
        font-weight: 300;
    }
}
