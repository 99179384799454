
            @import "src/app/global/_variables.scss";
.SelectContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    color: white;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}

.selectMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    top: 100%;
    opacity: 0;
    background-color: $background-color;
    border: 1px solid gray;
    border-radius: 10px;
}

.selectMenu.expanded {
    opacity: 100;
}

.selectOption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    transition-duration: 300ms;

    &.firstOption {
        border-radius: 9px 9px 0px 0px;
    }

    &.lastOption {
        border-radius: 0px 0px 9px 9px;
    }
}

.selectOption:hover {
    background-color: $primary-color;
    color: black;
}

.Icon {
    transition-duration: 300ms;
    transform: rotateZ(0);
}

.Icon.expanded {
    transform: rotateZ(180deg);
}
