
            @import "src/app/global/_variables.scss";
.greetingBlock {
    display: flex;
    flex-direction: column;
    gap: 56px;
    font-size: 20px;
    max-width: 1920px;
    margin: 220px 120px 370px 120px;
    
    @media screen and (max-width: 768px) {
        margin: 178px 24px 370px 24px;
    }
}

.greetingBlockTypography {
    width: 800px;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        font-weight: 500;
        text-align: left;
        width: 100%;
        word-break: break-word;
    }
}

.greetingBlockSpan {
    color: $primary-color;
    margin-left: 20%;
}

.greetingBlockSpan2 {
    margin-left: 40%;
    
}

.greetingBlockButton {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;    
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
