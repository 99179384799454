
            @import "src/app/global/_variables.scss";
.TooltipContainer {
    position: relative;
}

.tooltip {
    position: fixed;
    z-index: 20;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
}

.tooltip.show {
    opacity: 100;
}
