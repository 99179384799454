
            @import "src/app/global/_variables.scss";
.uniqText5 {
    font-size: 240px;
    font-weight: 600;
    height: 298px;
    background-image: linear-gradient(to bottom, #000000 15%, #cfcfcf 60%);
    margin: 0px 0px;
    color: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px transparent;

    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;

    @media screen and (max-width: 768px) {
        font-size: 60px;
        font-weight: 500;
        line-height: 74.46px;
        letter-spacing: 0.01em;
        height: auto;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        
    }

    @media screen and (max-width: 1300px) and (min-width: 768px) {
        font-size: 160px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 60px;
        font-weight: 500;
        line-height: 74.46px;
        letter-spacing: 0.01em;
        height: auto;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        
    }
}

.uniqMarqueeText {
    position: absolute;

    font-family: "Berthold Standard";
    font-size: 400px;
    font-style: normal;
    font-weight: 500;

    span {
        background-image: linear-gradient(to bottom, white, $background-color 80%);
        color: $background-color;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        text-transform: uppercase;
    }
    @media screen and (max-width: 768px) {
        position: relative;
        font-family: Berthold Standard;
        font-size: 120px;
        font-weight: 500;
        margin: 40px 0px;
    }
}

@keyframes letter-glow {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 1px white;
    }
    66% {
        opacity: 1;
        text-shadow: 0px 0px 20px black;
    }
    77% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
        text-shadow: 0px 0px 20px white;
    }
}
