
            @import "src/app/global/_variables.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;

    &::-webkit-scrollbar {
        width: 5px; /* ширина scrollbar */
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px; /* цвет дорожки */
        opacity: 50%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $primary-color; /* цвет плашки */
        border-radius: 20px; /* закругления плашки */
    }
    &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 12px;
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 100px;
    }

    
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"), url("../shared/fonts/Berthold/BertholdStandard.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"), url("../shared/fonts/Berthold/BertholdStandardLight.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"), url("../shared/fonts/Berthold/BertholdStandardMedium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"), url("../shared/fonts/Berthold/BertholdStandardBold.ttf");
    font-weight: 700;
}
