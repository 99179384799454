
            @import "src/app/global/_variables.scss";
.cookieNoticeBanner {
    position: fixed;
    flex-wrap: wrap;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 5000;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 922px;
    width: min(100%, 922px);

    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 38px 52px;

    transition-duration: 1000ms;

    &.close {
        opacity: 0;
        transform: translateY(+50px);
        pointer-events: none;
    }

    @media screen and (max-width: 768px) {
        width: calc(100vw - 52px);
        bottom: 0;
        flex-wrap: wrap;
        gap: 24px;
        padding: 40px 26px;
        margin: 0;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
}

.text {
    color: white;
    font-size: 20px;
    max-width: 60%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {
        max-width: 68%;
        justify-content: center;
        align-items: center;
    }
}

.decline {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 56px;
    padding: 15px 40px;
    cursor: pointer;

    transition-duration: 500ms;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: black;
        box-shadow: 0px 0px 25px 3px white;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {
        width:fit-content;
        height: fit-content;
        justify-content: center;
        align-items: center;
    }
}

.mobileDecline {
    display: none;
    background-color: transparent;
    border: 0;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

.accept {
    background-color: $primary-color;
    color: black;
    border-radius: 56px;
    padding: 15px 40px;
    border: 0px;

    cursor: pointer;

    transition-duration: 500ms;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: black;
        box-shadow: 0px 0px 25px 3px white;
    }

    @media screen and (max-width: 768px) {
        width: 260px;
        height: 56px;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {

        width:fit-content;
        height: fit-content;
        justify-content: center;
        align-items: center;
    }
}

.buttons {
    display: flex;
    gap: 24px;
}
