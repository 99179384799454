
            @import "src/app/global/_variables.scss";
.container {
    display: flex;
    align-items: center;
    gap: 64px;
    margin-left: 64px;

    @media screen and (max-width: 768px) {
        align-items: start;
        margin-left: 24px;
        gap: 24px;
    }
}

.typography {
    display: flex;
    flex-direction: column;
}

.name {
    @media screen and (max-width: 768px) {
        font-size: 32px;
        font-weight: 300;
    }
}

.role {
    @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
    }
}

.img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 0;
    flex-shrink: 0;
    background: lightgray 50% / cover no-repeat;

    @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}
