
            @import "src/app/global/_variables.scss";
.container {
    margin-top: 170px;
    margin-left: 335px;
    margin-right: 335px;

    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {
        margin-left: 120px;
        margin-right: 120px;
    }
}
.item {
    font-size: 36px;
    color: $text-color;
    font-weight: 700;
}

.subitem {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    color: $text-color;
}

.date {
    color: $primary-color;
    font-size: 14px;
}

.point {
    margin-left: 12px;
    list-style: disc;
}
