
            @import "src/app/global/_variables.scss";
.blog-post {
    width: 480px;
    background-color: $background-color;
    border: 1px solid rgba(37, 41, 41, 1);
    padding: 32px;
    transition-duration: 500ms;
    cursor: pointer;
    &:hover {
        background-color: white;
    }

    @media screen and (max-width: 768px) {
        width: 306px;
        // height: 272px;
        background-color: rgba($color: $background-color, $alpha: 0.5);
        border: 1px solid rgba(255, 255, 255, 0.5);
        &:hover {
            background-color: $background-color;
            .blog-post__text {
                color: white;
            }
            .blog-post__title {
                color: white;
            }
        }
    }
}
.blog-post__divider {
    height: 1px;
    background-color: rgba(37, 41, 41, 1);
    margin: 48px 0px;

    @media screen and (max-width: 768px) {
        margin: 24px 0px;
    }
}

.blog-post__title {
    display: flex;
    justify-content: space-between;
}

.blog-post__arrow {
    transition-duration: 500ms;
}

.blog-post__arrow_true {
    transform: rotate(45deg);
}

.blog-post__arrow_false {
    opacity: 0%;
}

.blog-post__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.01em;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.01em;
        word-break: break-word;
        width: 274px;
        height: 140px;
    }
}
