
            @import "src/app/global/_variables.scss";
.statistics {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    max-width: 500px;
}
.statistics__item-description {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.01em;
}
.statistics__item {
    margin-top: 32px;
}
