
            @import "src/app/global/_variables.scss";
.container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    flex-wrap: wrap;
    margin-top: 150px;

    @media screen and (min-width: 2000px) {
        width: 100vw;
        left: calc((-100vw + 1920px) / 2);
        position: relative;
    }
    @media screen and (max-width: 768px) {
        margin-top: 55px;
        gap: 32px;
    }
}

.someText {
    display: flex;
    justify-content: end;
    margin-right: 260px;
    @media screen and (min-width: 2000px) {
        position: relative;
        margin-right: calc((100vw - 1920px + 260px) / 2);
    }
}

.someTextInner {
    width: 635px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.01em;
        margin: 0px 24px 0px 24px;
        width: calc(100vw - 48px);
    }
}

.teammates {
    display: flex;
    overflow: hidden;
    max-width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 42px;
    }
}

.teammatesMarquee {
    overflow: hidden;
}

.teammateImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}

.blogPostMarquee {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.blogPost {
    display: flex;
    gap: 40px;
    margin-left: 40px;
    flex-wrap: nowrap;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding: 24px 16px 24px 16px;
        gap: 24px;
    }
}

.blogPostContainer2 {
    margin-top: 80px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.blogPostContainer3 {
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.blogPostContainer6 {
    margin-top: 80px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.blogPostContainer5 {
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
}

.typedText {
    position: absolute;
    margin-top: -100px;
    margin-left: 160px;
    z-index: -1;
    font-size: 142px;
    font-weight: 600;
    @media screen and (min-width: 2000px) {
        left: calc((100vw - 1960px) / 2);
    }

    span {
        background-image: linear-gradient(
            to bottom,
            white,
            $background-color 80%
        );
        color: $background-color;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        text-transform: uppercase;
    }

    @media screen and (max-width: 768px) {
        position: relative;
        left: 0px;
        margin: 0px 24px 0px 24px;
        margin-top: -120px;
        font-size: 60px;
        font-weight: 500;
    }
}
