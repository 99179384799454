
            @import "src/app/global/_variables.scss";
$background-color: #0c0d0d;
$text-color: white;
$primary-color: #cafe14;

html,
body {
    overflow-x: hidden;
}
body {
    position: relative;
}


body {
    background-color: $background-color;
    background: repeating-linear-gradient(
        to right,
        #0c0d0d,
        #0c0d0d calc(100% / 5),
        rgba(37, 41, 41, 1) calc(100% / 5),
        rgba(37, 41, 41, 1) calc(100% / 5 + 1px)
    );
}

.App {
    overflow: hidden;
}

$orbit-radius: 3000px;
$orbit-x: 400px;
$orbit-y: 200px;

$orbit-offset: 300px;

$circle-radius: 170px;

$orbit-radius-contact: 1500px;
$orbit-x-contact: 400px;
$orbit-y-contact: 200px;

$orbit-offset-contact: 300px;

$circle-radius-contact: 170px;

.app-orbit-container {

    &.path-name-condition__true {
        display: none;
    }

    position: absolute;
    z-index: -2;
    top: $orbit-y;
    left: $orbit-x;
    width: $orbit-radius;
    height: $orbit-radius;

    display: flex;
    align-items: center;
    justify-items: center;
    transform: scaleX(1.2);

    &.contact-block-orbit {
        top: -600px;
        left: calc($orbit-x-contact + 200px);
        width: $orbit-radius-contact;
        height: $orbit-radius-contact;
    }

    @media screen and (max-width: 768px) {
        top: 200px;
        left: -500px;
        &.contact-block-orbit {
            display: none;
        }
    }
}

.app-orbit {
    position: absolute;

    width: $orbit-radius;
    height: $orbit-radius;

    border: 2px solid gray;
    border-radius: 9999px;

    &.second-orbit {
        width: calc($orbit-radius - $orbit-offset);
        height: calc($orbit-radius - $orbit-offset);
        left: calc(50% - ($orbit-radius - $orbit-offset) / 2);
    }

    &.contact-block-orbit {
        width: $orbit-radius-contact;
        height: $orbit-radius-contact;

        &.second-orbit {
            width: calc($orbit-radius-contact - $orbit-offset-contact);
            height: calc($orbit-radius-contact - $orbit-offset-contact);
            left: calc(50% - ($orbit-radius-contact - $orbit-offset-contact) / 2);
        }
    }
}

.app-anim-circle {
    position: relative;
    width: $orbit-radius;
    height: $orbit-radius;

    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: orbit;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-name: orbit;
    -webkit-animation-duration: 20s;

    &.contact-block-orbit {
        width: $orbit-radius-contact;
        height: $orbit-radius-contact;
    }
}

.app-anim-dot {
    top: calc(0px - $circle-radius / 2);
    left: calc(50% - $circle-radius / 2);
    position: absolute;
    width: $circle-radius;
    height: $circle-radius;

    border: 1px solid gray;
    border-radius: 9999px;

    &.second-dot {
        top: calc(100% - $circle-radius / 2);
    }

    &.contact-block-orbit {
        top: calc(0px - $circle-radius-contact / 2);
        left: calc(50% - $circle-radius-contact / 2);

        width: $circle-radius-contact;
        height: $circle-radius-contact;

        &.second-dot {
            top: calc(100% - $circle-radius-contact / 2);
        }
    }
}

@keyframes orbit {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}
