
            @import "src/app/global/_variables.scss";
.CaseItem {
    position: relative;
    color: white;
    display: grid;
    flex-direction: column;
    background-color: black;
    color: white;
    font-weight: 300;
    font-size: 96px;

    padding: 0px 120px 20px 120px;

    border-bottom: 2px solid gray;

    grid-template-rows: 0fr;

    &.expanded {
        background-color: $primary-color;
        color: black;
    }

    @media screen and (min-width: 2000px) {
        width: 100vw;
        left: calc((-100vw + 1920px)/2);
        padding-left: calc((100vw - 1920px)/2);
        padding-right: calc((100vw - 1920px)/2);
    }

    @media screen and (max-width: 768px) {
        padding: 0px 24px 20px 24px;
    }
}

.headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 32px;
    font-weight: 300;
    margin-right: 120px;
    padding-right: 120px;

    @media screen and (min-width: 2000px) {
        padding-right: calc((100vw - 1920px + 120px));
    }

    @media screen and (max-width: 768px) {
        padding-right: 24px;
        margin-right: 0;
    }
}

.title {
    font-size: 96px;
    font-weight: 300;
    margin-block-end: 56px;
    margin-block-start: 56px;

    @media screen and (max-width: 768px) {
        font-size: 42px;
        font-weight: 300;
        max-width: 250px;
        margin-block-end: 24px;
        margin-block-start: 28px;
    }
}

.slider {
    position: relative;
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: all ease-in-out 1s;
    width: calc(100vw - 120px);
    max-width: 1920px;
    @media screen and (max-width: 768px) {
        width: calc(100vw - 24px);
    }
}

.slider.expanded {
    grid-template-rows: 1fr;
}

.sliderContainer {
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 86.5px;
    padding-right: 120px;
    cursor: grab;
    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;

    &.active {
        cursor: grabbing;
    }

    @media screen and (max-width: 768px) {
        padding-right: 24px;
    }
}

.sliderItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 461px;
}

.caseTitle {
    font-family: Berthold Standard;
    font-size: 54px;
    font-weight: 400;
    line-height: 79.42px;
    text-align: left;
    margin-block-end: 16px;
    margin-block-start: 12px;

    @media screen and (max-width: 768px) {
        font-size: 42px;
        font-weight: 400;
        margin-block-end: 4px;
        margin-block-start: 12px;
    }
}

.caseList {
    font-family: Berthold Standard;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: right;
    text-transform: uppercase;
    color: #898989;
    &.expanded {
        display: none;
    }

    position: relative;
    right: 120px;

    @media screen and (max-width: 768px) {
        place-self: start;
        right: 0;

        font-size: 14px;
        font-weight: 400;
    }

    @media screen and (min-width: 2000px) {
        position: relative;
        right: 24px;
        right: 240px;
    }
}

.caseDescription {
    font-family: Berthold Standard;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 400;
    }
}

.footage {
    border-radius: 20px;
    pointer-events: none;

    @media screen and (max-width: 768px) {
        width: 298px;
        height: 183px;
    }
}

.mobileExpandIcon {
    display: block;
    transform: rotateZ(180deg) scale(1.5);
    transition: 0.3s;
    &.expanded {
        transform: rotateZ(0deg) scale(1.5);
        stroke: black;
    }
    @media screen and (max-width: 768px) {
        display: block;
        transform: rotateZ(180deg);
        transition: 0.3s;
        &.expanded {
            transform: rotateZ(0deg);
            stroke: black;
        }
    }
}
