
            @import "src/app/global/_variables.scss";
.contactBlock {
    position: relative;
    height: 100%;
    background-color: white;
    margin-top: 250px;

    @media screen and (min-width: 2000px) {
        width: 100vw;
        left: calc((-100vw + 1920px) / 2);
    }

    @media screen and (min-width: 768px) and (max-width: 2000px) {
        left: 0px;
    }
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 90px;
}

.titleEmail {
    @media screen and (max-width: 768px) {
        height: 24px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: -0.01em;
    }
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    padding: 0px 120px;
    margin-top: 96px;
}

.textArea {
    width: 100%;
    max-width: calc(372 * 3px + 80px);
    min-width: 530px;
    height: 150px;

    @media screen and (max-width: 768px) {
        min-width: min-content;
        width: calc(100vw - 60px);
    }
}

.textFields {
    display: flex;
    gap: 40px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.arrow {
    transition-duration: 500ms;

    &.hover {
        transform: rotate(45deg);
    }
}

.wave {
    position: absolute;
    width: calc(100%);
    transform: scaleY(3);
    top: -250px;
    z-index: -1;
}

.formButton {
    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100vw - 60px);
    }
}

.error {
    color: red;
    margin-top: 20px;
    font-weight: 600;
    font-size: 24px;
}

.privacyPolicy {
    margin-top: 48px;

    display: flex;
    align-items: center;
    gap: 4px;

    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
    }
}

.privacyCheckBox {
    position: absolute;
    z-index: -10;
    opacity: 0;
}

.privacyCheckBox + .checkBoxLabel {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.privacyCheckBox + .checkBoxLabel::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.privacyCheckBox:checked + .checkBoxLabel::before {
    border-color: $primary-color;
    background-color: $primary-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='black' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.privacyLink {
    text-decoration: underline;
    cursor: pointer;
}

.successMessage {
    display: flex;
    max-width: fit-content;

    padding: 12px 0px;
    border-bottom: 3px solid $primary-color;
    margin-top: 42px;

    color: black;
    text-align: center;
    font-family: "Berthold Standard";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
