
            @import "src/app/global/_variables.scss";
.formControl {
    width: 100%;
    max-width: 372px;
    min-width: 150px;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    transition-duration: 500ms;
    border-bottom: 4px solid gray;

    &.focus {
        transition-duration: 500ms;
        border-bottom: 4px solid $primary-color;
    }

    &.invalid {
        transition-duration: 500ms;
        border-bottom: 4px solid red;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 50px white inset !important;
    }
}

.input {
    background-color: transparent;
    padding: 0px 5px;
    width: 60%;
    height: 100%;
    outline: none;
    border: 0px;
    font-size: 20px;
}

.inputLabel {
    transition-duration: 300ms;
    padding-left: 32px;
}
.inputLabel.focus {
    color: gray;
    padding-left: 0px;
}

.warning {
    position: absolute;
    top: calc(100% + 16px);

    color: red;
    opacity: 1;
    transition-duration: 500ms;

    &.hide {
        opacity: 0;
    }
}