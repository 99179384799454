
            @import "src/app/global/_variables.scss";
.stack__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        width: 100vw;
    }
}

.stack__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        margin-left: 86px;
    }
}