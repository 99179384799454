
            @import "src/app/global/_variables.scss";
.worksBlock {
    display: flex;
    flex-direction: column;
    margin-top: 320px;
    
}

.title {
    margin: 0px 120px;

    @media screen and (max-width: 768px) {
        margin: 0px 24px;
        font-size: 32px;
        font-weight: 500;
    }
}

.someText {
    display: flex;
    justify-content: end;

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.uniqText {
    width: 100vw;
    left: 0;
}