
            @import "src/app/global/_variables.scss";
.baseButton {
    color: $dark-text-color;
    transition-duration: 1000ms;
    padding: 16px 32px;
    border-radius: 48px;
    border: 0px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0 10px;

    max-height: 57px;

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    cursor: pointer;
    &.large {
        padding: 32px 64px;
    }
    &.primary {
        background-color: $primary-color;
        border: 1px $primary-color solid;
        color: $dark-text-color;
        &:hover {
            background-color: white;
            border: 1px gray solid;
        }
    }
    &.secondary {
        color: $text-color;
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.04),
            rgba(255, 255, 255, 0.2)
        );
        backdrop-filter: blur(3.5px);
        box-shadow: 1.5px 1px rgba($color: #fff, $alpha: 0.5),
            -1.5px -1px rgba($color: #fff, $alpha: 0.3);

        &:hover {
            box-shadow: 1.5px -1px rgba($color: #fff, $alpha: 0.5),
                -1.5px 1px rgba($color: #fff, $alpha: 0.3);
        }
    }

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
