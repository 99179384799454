
            @import "src/app/global/_variables.scss";
.navigationList {
    display: flex;
    gap: 0 56px;
    list-style: none;
}

.navigationItem {
    color: $text-color;
    font-size: 20px;
    cursor: pointer;
    transition-duration: 500ms;
    text-decoration: none;
    &:hover {
        color: $primary-color;
    }
}
