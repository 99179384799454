
            @import "src/app/global/_variables.scss";
.stackBlock {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 64px;

    width: 100vw;
    left: calc((-100vw + 1920px) / 2);

    @media screen and (min-width: 768px) and (max-width: 2000px) {
        left: 0px;
    }
    @media screen and (max-width: 768px) {
        gap: 0px;
        left: 0;
    }
}

.explanation {
    display: none;

    @media screen and (max-width: 768px) {
        position: relative;
        top: -200px;
        margin: 0px 24px 0px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 23px;
    }
}

.explanationText {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    line-height: 30.4px;
    text-align: center;
}

.technologies {
    display: flex;
    justify-content: space-between;
    gap: 133px;
    margin: 240px 120px 0px 120px;
    padding-left: calc((100% - 1920px) / 2);
    padding-right: calc((100% - 1920px) / 2);

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 42px;
        margin: 0px 24px 0px 24px;
    }

    @media screen and (max-width: 1100px) and (min-width: 768px) {
        margin-top: 30px;
    }
}

.statistics {
    padding-top: 30px;
}

.stack {
    margin-top: 120px;
    padding-left: calc((100% - 1920px) / 2);
    padding-right: calc((100% - 1920px) / 2);

    @media screen and (max-width: 2000px) and (min-width: 768px) {
        padding-left: 120px;
        padding-right: 120px;
    }

    @media screen and (max-width: 768px) {
        position: absolute;
        flex-wrap: nowrap;
        gap: 86px;
        margin-top: 65px;
        padding: 0;
        width: 100vw;
    }
}

.headline {
    position: absolute;
    margin-left: 120px;
    top: -276px;
    place-self: start;
    padding-left: calc((100% - 1920px) / 2);
    padding-right: calc((100% - 1920px) / 2);
    font-size: 142px;
    font-weight: 600;
    pointer-events: none;

    span {
        background-image: linear-gradient(
            to bottom,
            #000000 15%,
            #cfcfcf 60%
        );
        color: white;
        mix-blend-mode: multiply;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        text-transform: uppercase;
    }

    @media screen and (max-width: 768px) {
        margin: 0 24px 0 24px;
        font-size: 80px;
        font-size: 60px;
        font-weight: 500;
        top: 0;
        position: relative;
    }

    @media screen and (max-width: 1100px) and (min-width: 768px) {
        font-size: 80px;
    }
}

.waveUp {
    position: absolute;
    top: -200px;
    width: calc(100vw);
    transform: scaleY(3);
    z-index: -1;
}

.waveDown {
    position: absolute;
    top: calc(100% + 100px);
    width: calc(100vw);
    transform: rotateZ(180deg) scaleY(3);
    z-index: -1;
}

// @keyframes scroll-left {
//     0% {
//         transform: translateX(100%);
//     }

//     100% {
//         transform: translateX(-300%);
//     }
// }
