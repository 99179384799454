
            @import "src/app/global/_variables.scss";
.home-page__container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    z-index: 999;

    padding-left: calc((100% - 1920px)/2);
    padding-right: calc((100% - 1920px)/2);
}


